import { defineStore } from 'pinia';

export const useSweepstakeDataStore = defineStore({
  id: 'sweepstake-data-store',
  state: () => {
    return {
      live: [
        {
          id: 'GU1464',
          type: 'Blockchain',
          state: 3,
          name: 'Genuine Undead',
          description: '',
          imageUrl: 'https://i.seadn.io/gae/80K1Mmjen8bPpjtlPiRToiZ2RRmoa0vD09yMtjT_QT5REFLQUsAMnJxW_pCWUS_T5sb0hiKDMRhMbS3ey6S6m4ybJSLPMWNh_XFBIw?auto=format&w=1000',
          featured: false,
          public: true,
          prizes: [
            {
              type: 'NFT',
              name: 'Genuine Undead #1464',
              shortName: 'GU1464',
              imageUrl: 'https://i.seadn.io/gae/80K1Mmjen8bPpjtlPiRToiZ2RRmoa0vD09yMtjT_QT5REFLQUsAMnJxW_pCWUS_T5sb0hiKDMRhMbS3ey6S6m4ybJSLPMWNh_XFBIw?auto=format&w=1000',
              contractAddress: '',
              contractType: '',
              tokenId: '1464',
              staked: true,
              value: '3.5',
              quantity: 1,
            },
          ],
          entryPackages: [
            {
              id: 1,
              entryCount: 1,
              price: '0',
              popular: true,
              currencyCode: 'ETH',
              available: true,
              entryAction: {
                type: 'Client',
                contractAddress: '',
                externalId: '',
              },
            },
          ],
          category: {
            id: 1,
            name: 'metawinners-nft',
            label: 'MetaWinners NFT Holders',
          },
          maxEntriesPerUser: 1,
          entryCount: 0,
          participants: 0,
          metadata: {
            placeholder: {
              landingDisabled: true,
              modalDisabled: true,
              cardButtonLabel: 'Coming soon',
              footerText: 'MetaWinner NFT Holders Only',
              footerText2: 'Visit The ARENA Discord for More',
              externalLink: 'https://discord.gg/the-arena',
            },
          },
          launchTime: '',
          openTime: '',
          closeTime: '',
          closeCondition: {
            type: 'Fund',
            max: 0,
            remaining: 0,
          },
          blockchain: {
            entryRequirements: [
              {
                type: 'NFT',
                imageUrl: '/img/logos/MetaWin-icon-white-cyan.png',
                placeholder: true,
              },
            ],
            externalId: '',
            contractAddress: '',
            seller: {
              id: 1,
              displayName: '',
              isPlayer: false,
            },
          },
        },
        {
          id: 'BAYC2680',
          type: 'Blockchain',
          state: 3,
          name: 'Bored Ape Yacht Club',
          description: '',
          imageUrl: 'https://img.seadn.io/files/bc33f77fe5df0caac0a96bd9c44b9348.png?fit=max&w=1000',
          featured: false,
          public: true,
          prizes: [
            {
              type: 'NFT',
              name: 'Bored Ape Yacht Club #2680',
              shortName: 'BAYC2680',
              imageUrl: 'https://img.seadn.io/files/bc33f77fe5df0caac0a96bd9c44b9348.png?fit=max&w=1000',
              contractAddress: '',
              contractType: '',
              tokenId: '2680',
              staked: true,
              value: '80',
              quantity: 1,
            },
          ],
          entryPackages: [
            {
              id: 1,
              entryCount: 1,
              price: '0',
              popular: true,
              currencyCode: 'ETH',
              available: true,
              entryAction: {
                type: 'Client',
                contractAddress: '',
                externalId: '',
              },
            },
          ],
          category: {
            id: 1,
            name: 'metawinners-nft',
            label: 'MetaWinners NFT Holders',
          },
          maxEntriesPerUser: 1,
          entryCount: 0,
          participants: 0,
          metadata: {
            hidePrice: true,
            placeholder: {
              landingDisabled: true,
              modalDisabled: true,
              cardButtonLabel: 'Coming soon',
              footerText: 'MetaWinner NFT Holders Only',
              footerText2: 'Visit The ARENA Discord for More',
              externalLink: 'https://discord.gg/the-arena',
            },
          },
          launchTime: '',
          openTime: '',
          closeTime: '',
          closeCondition: {
            type: 'Fund',
            max: 0,
            remaining: 0,
          },
          blockchain: {
            entryRequirements: [
              {
                type: 'NFT',
                imageUrl: '/img/logos/MetaWin-icon-white-cyan.png',
                placeholder: true,
              },
            ],
            externalId: '',
            contractAddress: '',
            seller: {
              id: 1,
              displayName: '',
              isPlayer: false,
            },
          },
        },
        {
          id: 'MB1811',
          type: 'Blockchain',
          state: 3,
          name: 'Genuine Undead',
          description: '',
          imageUrl: 'https://img.seadn.io/files/2d8e71f5a7c9de7734185d7ec4e51175.png?fit=max&w=1000',
          featured: false,
          public: true,
          prizes: [
            {
              type: 'NFT',
              name: 'Genuine Undead #3544',
              shortName: 'GU3544',
              imageUrl: 'https://img.seadn.io/files/2d8e71f5a7c9de7734185d7ec4e51175.png?fit=max&w=1000',
              contractAddress: '',
              contractType: '',
              tokenId: '3544',
              staked: true,
              value: '25',
              quantity: 1,
            },
          ],
          entryPackages: [
            {
              id: 1,
              entryCount: 1,
              price: '0',
              popular: true,
              currencyCode: 'ETH',
              available: true,
              entryAction: {
                type: 'Client',
                contractAddress: '',
                externalId: '',
              },
            },
          ],
          category: {
            id: 1,
            name: 'metawinners-nft',
            label: 'MetaWinners NFT Holders',
          },
          maxEntriesPerUser: 1,
          entryCount: 0,
          participants: 0,
          metadata: {
            placeholder: {
              landingDisabled: true,
              modalDisabled: true,
              cardButtonLabel: 'Coming soon',
              footerText: 'MetaWinner NFT Holders Only',
              footerText2: 'Visit The ARENA Discord for More',
              externalLink: 'https://discord.gg/the-arena',
            },
          },
          launchTime: '',
          openTime: '',
          closeTime: '',
          closeCondition: {
            type: 'Fund',
            max: 0,
            remaining: 0,
          },
          blockchain: {
            entryRequirements: [
              {
                type: 'NFT',
                imageUrl: '/img/logos/MetaWin-icon-white-cyan.png',
                placeholder: true,
              },
            ],
            externalId: '',
            contractAddress: '',
            seller: {
              id: 1,
              displayName: '',
              isPlayer: false,
            },
          },
        },
        {
          id: 'Doodle2000',
          type: 'Blockchain',
          state: 3,
          name: 'Doodle',
          description: '',
          imageUrl: 'https://i.seadn.io/gcs/files/86cf9363f819abcf1ec303d1daeb4a73.png?auto=format&w=1000',
          featured: false,
          public: true,
          prizes: [
            {
              type: 'NFT',
              name: 'Doodle',
              shortName: 'Doodle2000',
              imageUrl: 'https://i.seadn.io/gcs/files/86cf9363f819abcf1ec303d1daeb4a73.png?auto=format&w=1000',
              contractAddress: '',
              contractType: '',
              tokenId: '2000',
              staked: true,
              value: '25',
              quantity: 1,
            },
          ],
          entryPackages: [
            {
              id: 1,
              entryCount: 1,
              price: '0',
              popular: true,
              currencyCode: 'ETH',
              available: true,
              entryAction: {
                type: 'Client',
                contractAddress: '',
                externalId: '',
              },
            },
          ],
          category: {
            id: 1,
            name: 'metawinners-nft',
            label: 'MetaWinners NFT Holders',
          },
          maxEntriesPerUser: 1,
          entryCount: 0,
          participants: 0,
          metadata: {
            hidePrice: true,
            placeholder: {
              landingDisabled: true,
              modalDisabled: true,
              cardButtonLabel: 'Coming soon',
              footerText: 'MetaWinner NFT Holders Only',
              footerText2: 'Visit The ARENA Discord for More',
              externalLink: 'https://discord.gg/the-arena',
            },
          },
          launchTime: '',
          openTime: '',
          closeTime: '',
          closeCondition: {
            type: 'Fund',
            max: 0,
            remaining: 0,
          },
          blockchain: {
            entryRequirements: [
              {
                type: 'NFT',
                imageUrl: '/img/logos/MetaWin-icon-white-cyan.png',
                placeholder: true,
              },
            ],
            externalId: '',
            contractAddress: '',
            seller: {
              id: 1,
              displayName: '',
              isPlayer: false,
            },
          },
        },
        {
          id: 'MAYC21605',
          type: 'Blockchain',
          state: 3,
          name: 'Mutant Ape Yacht Club',
          description: '',
          imageUrl: 'https://img.seadn.io/files/1405ed847978222b159db8bbdfbfe0cd.png?fit=max&w=1000',
          featured: false,
          public: true,
          prizes: [
            {
              type: 'NFT',
              name: 'Mutant Ape Yacht Club #21605',
              shortName: 'MAYC21605',
              imageUrl: 'https://img.seadn.io/files/1405ed847978222b159db8bbdfbfe0cd.png?fit=max&w=1000',
              contractAddress: '',
              contractType: '',
              tokenId: '21605',
              staked: true,
              value: '28',
              quantity: 1,
            },
          ],
          entryPackages: [
            {
              id: 1,
              entryCount: 1,
              price: '0',
              popular: true,
              currencyCode: 'ETH',
              available: true,
              entryAction: {
                type: 'Client',
                contractAddress: '',
                externalId: '',
              },
            },
          ],
          category: {
            id: 1,
            name: 'metawinners-nft',
            label: 'MetaWinners NFT Holders',
          },
          maxEntriesPerUser: 1,
          entryCount: 0,
          participants: 0,
          metadata: {
            hidePrice: true,
            placeholder: {
              landingDisabled: true,
              modalDisabled: true,
              cardButtonLabel: 'Coming soon',
              footerText: 'MetaWinner NFT Holders Only',
              footerText2: 'Visit The ARENA Discord for More',
              externalLink: 'https://discord.gg/the-arena',
            },
          },
          launchTime: '',
          openTime: '',
          closeTime: '',
          closeCondition: {
            type: 'Fund',
            max: 0,
            remaining: 0,
          },
          blockchain: {
            entryRequirements: [
              {
                type: 'NFT',
                imageUrl: '/img/logos/MetaWin-icon-white-cyan.png',
                placeholder: true,
              },
            ],
            externalId: '',
            contractAddress: '',
            seller: {
              id: 1,
              displayName: '',
              isPlayer: false,
            },
          },
        },
        {
          id: 'MB1811',
          type: 'Blockchain',
          state: 3,
          name: 'Moonbirds',
          description: '',
          imageUrl: 'https://img.seadn.io/files/062a64660aac2bab9a3948889832be1d.png?fit=max&w=1000',
          featured: false,
          public: true,
          prizes: [
            {
              type: 'NFT',
              name: 'Moonbirds #1811',
              shortName: 'MB1811',
              imageUrl: 'https://img.seadn.io/files/062a64660aac2bab9a3948889832be1d.png?fit=max&w=1000',
              contractAddress: '',
              contractType: '',
              tokenId: '1811',
              staked: true,
              value: '30',
              quantity: 1,
            },
          ],
          entryPackages: [
            {
              id: 1,
              entryCount: 1,
              price: '0',
              popular: true,
              currencyCode: 'ETH',
              available: true,
              entryAction: {
                type: 'Client',
                contractAddress: '',
                externalId: '',
              },
            },
          ],
          category: {
            id: 1,
            name: 'metawinners-nft',
            label: 'MetaWinners NFT Holders',
          },
          maxEntriesPerUser: 1,
          entryCount: 0,
          participants: 0,
          metadata: {
            placeholder: {
              landingDisabled: true,
              modalDisabled: true,
              cardButtonLabel: 'Coming soon',
              footerText: 'MetaWinner NFT Holders Only',
              footerText2: 'Visit The ARENA Discord for More',
              externalLink: 'https://discord.gg/the-arena',
            },
          },
          launchTime: '',
          openTime: '',
          closeTime: '',
          closeCondition: {
            type: 'Fund',
            max: 0,
            remaining: 0,
          },
          blockchain: {
            entryRequirements: [
              {
                type: 'NFT',
                imageUrl: '/img/logos/MetaWin-icon-white-cyan.png',
                placeholder: true,
              },
            ],
            externalId: '',
            contractAddress: '',
            seller: {
              id: 1,
              displayName: '',
              isPlayer: false,
            },
          },
        },
        {
          id: 'BAYC7634',
          type: 'Blockchain',
          state: 3,
          name: 'Bored Ape Yacht Club',
          description: '',
          imageUrl: 'https://img.seadn.io/files/786466a714764be74e14cdc28083251b.png?fit=max&w=1000',
          featured: false,
          public: true,
          prizes: [
            {
              type: 'NFT',
              name: 'Bored Ape Yacht Club #7634',
              shortName: 'BAYC7634',
              imageUrl: 'https://img.seadn.io/files/786466a714764be74e14cdc28083251b.png?fit=max&w=1000',
              contractAddress: '',
              contractType: '',
              tokenId: '7634',
              staked: true,
              value: '87',
              quantity: 1,
            },
          ],
          entryPackages: [
            {
              id: 1,
              entryCount: 1,
              price: '0',
              popular: true,
              currencyCode: 'ETH',
              available: true,
              entryAction: {
                type: 'Client',
                contractAddress: '',
                externalId: '',
              },
            },
          ],
          category: {
            id: 1,
            name: 'metawinners-nft',
            label: 'MetaWinners NFT Holders',
          },
          maxEntriesPerUser: 1,
          entryCount: 0,
          participants: 0,
          metadata: {
            hidePrice: true,
            placeholder: {
              landingDisabled: true,
              modalDisabled: true,
              cardButtonLabel: 'Coming soon',
              footerText: 'MetaWinner NFT Holders Only',
              footerText2: 'Visit The ARENA Discord for More',
              externalLink: 'https://discord.gg/the-arena',
            },
          },
          launchTime: '',
          openTime: '',
          closeTime: '',
          closeCondition: {
            type: 'Fund',
            max: 0,
            remaining: 0,
          },
          blockchain: {
            entryRequirements: [
              {
                type: 'NFT',
                imageUrl: '/img/logos/MetaWin-icon-white-cyan.png',
                placeholder: true,
              },
            ],
            externalId: '',
            contractAddress: '',
            seller: {
              id: 1,
              displayName: '',
              isPlayer: false,
            },
          },
        },

      ],
    };
  },
  actions: {},
});
