<template>
  <component
    :is="category.isMiniGames ? ListMiniGamesCarouselMain : ListSweepstakeCategory"
    v-for="(category, index) in categoriesDisplay"
    :key="index"
    :title="category.title"
    :category-name="category.categoryFilter"
    :display-mode="{ mode: category?.displayMode, carouselInitialState: category?.carouselInitialState}"
    :sweepstakes-display="category?.sweepstakes"
    :exchange-rate-eth-usd="exchangeRateEthUsd"
    :hide-price="category.hidePrice"
    :card-size="category.cardSize"
    show-rtp
    show-volatility
    @card-action="cardAction"
  />
</template>

<script>
export default defineComponent({
  name: 'ListSweepstakeCategorised',
  props: {
    sweepstakesDisplay: {
      type: Object,
      default: null,
    },
    instantWinGamesPromoted: {
      type: Object,
      default: null,
    },
    liveGamesPromoted: {
      type: Object,
      default: null,
    },
    exchangeRateEthUsd: {
      type: Number,
      default: null,
    },
    displayCategoriesData: {
      type: Array,
      default: null,
    },
  },
  emits: [
    'card-action',
  ],
  setup() {
    const ListSweepstakeCategory = resolveComponent('ListSweepstakeCategory');
    const ListMiniGamesCarouselMain = resolveComponent('ListMiniGamesCarouselMain');
    return { ListSweepstakeCategory, ListMiniGamesCarouselMain, };
  },
  computed: {
    supportedCategories() {
      const categories = this.displayCategoriesData.filter(el => !el.isFilterAppBased).map(el => el.categoryFilter);
      return categories;
    },
    excludedFromClosingToday() {
      const categories = this.displayCategoriesData.filter(el => el.excludeFromClosingToday).map(el => el.categoryFilter);
      return categories;
    },
    categoriesDisplay() {
      const result = this.displayCategoriesData || [];
      result.forEach((category) => {
        category.sweepstakes = [];
        const { categoryFilter, } = category;

        // Categories with filter defined in data
        if (!category.isFilterAppBased) {
          category.sweepstakes = this.sweepstakesDisplay
          .filter(item => (item?.category?.name === categoryFilter) || (item?.category?.name === 'special-feature' && categoryFilter === 'featured'));

        // Categories with filter programatically made
        } else if (categoryFilter === 'closing-today') {
          category.sweepstakes = this.sweepstakesClosingToday;
        } else if (categoryFilter === 'uncategorised') {
          category.sweepstakes = this.sweepstakesUncategorised;
        } else if (categoryFilter === 'instant-win-games') {
          category.sweepstakes = this.instantWinGamesPromoted;
          category.cardSize = 'card-small';
        } else if (categoryFilter === 'live-games') {
          category.sweepstakes = this.liveGamesPromoted;
          category.cardSize = 'card-small';
        } else if (categoryFilter === 'mini-games') {
          category.isMiniGames = true;
        }
      });
      return result;
    },
    sweepstakesClosingToday() {
      let sweepstakes = this.sweepstakesDisplay;
      const now = this.$dayjs();
      sweepstakes = sweepstakes
      .filter(item => !this.excludedFromClosingToday.includes(item?.category?.name) && item?.enterFrom !== 0)
      .filter((item) => {
        if (item.closeTime) {
          const endDate = this.$dayjs(item.closeTime);
          const expiresIn = endDate.diff(now, 's');
          if (expiresIn <= 86400) { return item; }
        }

        return false;
      });
      return sweepstakes;
    },
    sweepstakesUncategorised() {
      let sweepstakes = this.sweepstakesDisplay;
      sweepstakes = sweepstakes
      .filter(item => !this.supportedCategories.includes(item?.category?.name))
      .filter(a => !this.sweepstakesClosingToday.map(b => b.id).includes(a.id));
      return sweepstakes;
    },
  },
  methods: {
    cardAction(item) {
      this.$emit('card-action', item);
    },
  },
});
</script>
