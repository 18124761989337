<template>
  <div class="relative lg:mb-3">
    <h2 class="block font-bold tracking-wider text-lg lg:text-xl leading-none mb-1.5 lg:mb-0">
      Mini Games
    </h2>

    <div class="relative">
      <HorizontalScroller
        :items-length="tournamentsDisplay.length"
        @dragging="handleToggleDragging"
      >
        <transition-group name="fade-fast">
          <template v-for="(game, idx) in tournamentsDisplay" :key="game?.id">
            <div
              v-if="game.id !== hiddenGameId"
              class="snap-start hardware-accelerate grow-0 shrink-0 mr-1 lg:mr-2 relative w-32 lg:w-40 text-center bg-slate-900 rounded-xl overflow-hidden pb-1 group"
              :class="[
                isDragging ? 'pointer-events-none' : 'cursor-pointer',
                game.state === 1 ? '!cursor-auto' : null,
              ]"
              @click.prevent.stop="selectGame(game, idx)"
            >
              <div
                v-if="isRestricted(game)"
                class="absolute z-50 text-xs opacity-0 top-0 left-0 right-0 bottom-0 group-hover:opacity-100 bg-black/75 group-hover:translate-y-0 transition-opacity duration-300 flex items-center font-bold"
              >
                <div class="text-xs opacity-0 top-10 group-hover:opacity-100 -mt-4 group-hover:mt-0 transition-all duration-200 text-center mx-3 font-bold">
                  This game is not available in your current location.
                </div>
              </div>
              <div
                class="w-32 h-32 lg:w-40 lg:h-40 overflow-hidden rounded-xl border-2 relative transition-all duration-200 scale-[0.98]"
                :class="[
                  game?.id === gameSelected?.id
                    ? 'border-cyan-500'
                    : 'border-transparent',
                  game.state === 1 ? '' : 'lg:hover:scale-100',
                ]"
              >
                <transition name="fade">
                  <Spinner
                    v-if="game.isLoading"
                    size="md"
                    class="absolute z-10 left-1/2 -ml-4 top-1/2 -mt-5 rounded-full bg-black flex items-center justify-center"
                  />
                </transition>

                <ImageLoader
                  :classes="[
                    'object-cover h-full w-full block relative z-0',
                    { 'scale-[1.03]': hasPrize(game.metadata) },
                  ]"
                  card-dark
                  :image-url="game.assets.imageUrl"
                  :animation-url="!isMetaMaskMobile ? game.assets.animationUrl : null"
                  :alt="game?.name"
                  width="160"
                  height="160"
                  loading-type="eager"
                  force-video-autoplay
                />
                <div
                  v-if="hasPrize(game.metadata) || isLimitedEditionComp(game)"
                  class="absolute bottom-0 right-0 z-40"
                >
                  <CardRaffleCountdownEnter
                    v-if="leaderboardEndTime(game.metadata)"
                    :close-time="leaderboardEndTime(game.metadata)"
                    close-message="Ended"
                  />
                  <CardRaffleCountdownEnter
                    v-else
                    :close-time="isLimitedEditionCompDateTime(game)"
                    :coming-soon="isGameWaiting(game.state)"
                    close-message="Ended"
                  />
                </div>

                <template v-if="!game.public">
                  <div
                    v-if="!game.public"
                    class="absolute left-[6px] top-[6px] uppercase bg-red-700 text-white rounded-xl px-2 text-xs font-bold"
                  >
                    Internal
                  </div>
                  <div
                    v-if="game.state < 4"
                    class="absolute left-[6px] top-[26px] uppercase bg-orange-600 text-white rounded-xl px-2 text-xs font-bold"
                  >
                    {{ ["Scheduled", "Launching", "Waiting"][game.state - 1] }}
                  </div>
                </template>
              </div>
              <div v-if="!game?.metadata?.isNew && !game.comingSoon && hasPrize(game.metadata)">
                <div class="font-bold text-2xs mt-1">Leaderboard Prize</div>
                <CurrencyDisplayTopWin
                  text-size="text-md"
                  :prize-value="{
                    value: game.metadata.leaderboardPrizeTotal,
                    currency: 'ETH',
                  }"
                  is-top-win
                />
              </div>
              <div
                v-else
                class="text-xs lg:text-sm flex items-center w-full h-10 font-light justify-center"
              >
                <h2 class="font-bold leading-none p-1">{{ game.name }}</h2>
              </div>
            </div>
          </template>

          <NuxtLink
            v-if="isUserLogged && $config.public.GAMES_ACTIVE"
            to="/games"
            class="hardware-accelerate grow-0 shrink-0 mr-1 lg:mr-2 relative w-32 lg:w-40 text-center bg-slate-900 rounded-xl"
          >
            <figure
              class="w-32 h-32 lg:w-40 lg:h-40 overflow-hidden rounded-xl border-2 border-transparent relative transition-all duration-200 lg:hover:scale-[1.03]"
            >
              <ImageLoader
                :classes="['object-cover h-full w-full block relative z-0']"
                card-dark
                image-url="https://content.prod.platform.metawin.com/minigames/more-games160x160.png"
                alt="More Games"
                width="160"
                height="160"
                loading-type="eager"
                force-video-autoplay
              />
              <div
                v-if="!hasPrizes"
                class="absolute w-full h-full z-20 top-0 left-0 flex items-center justify-center bg-black/10 transition-all group-hover:bg-black/0"
              >
                <ButtonButton
                  theme="grey"
                  size="2xs"
                  class="drop-shadow-[0_0px_30px_rgba(255,255,255,0.5)] font-bold"
                >
                  More Games
                </ButtonButton>
              </div>
            </figure>

            <div
              v-if="hasPrizes"
              class="text-xs lg:text-sm flex items-center w-full h-10 font-light justify-center"
            >
              <h2 class="font-bold leading-none p-1">More Games</h2>
            </div>
          </NuxtLink>
        </transition-group>
      </HorizontalScroller>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia';
import { dragscroll } from 'vue-dragscroll';
import { useMinigamesStore } from '@/store/minigames';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';
import { useDeviceStore } from '@/store/device';
import { useLocationStore } from '@/store/location';

export default defineComponent({
  name: 'ListMiniGamesCarouselMain',
  directives: {
    dragscroll,
  },
  data() {
    return {
      isDragging: false,
      poller: null,
      nextEndTime: null,
      nextEndTimeTimeout: null,
      hiddenGameId: null,
    };
  },
  computed: {
    ...mapState(useDeviceStore, ['isMetaMaskMobile',]),
    ...mapState(useAuthStore, ['isUserLogged',]),
    ...mapState(useLocationStore, ['isGamingRestricted',]),
    ...mapWritableState(useMinigamesStore, [
      'gameSelected',
      'tournaments',
      'leaderboardSchedules',
    ]),
    hasPrize() {
      return metadata => !!metadata?.leaderboardPrizeTotal;
    },
    hasPrizes() {
      return this.tournamentsDisplay.filter(game => game.metadata?.leaderboardPrizeTotal).length > 0;
    },
    leaderboardEndTime() {
      return (metadata) => {
        const leaderboardScheduleName = metadata?.leaderboardScheduleName;
        if (!leaderboardScheduleName) {
          return null;
        }

        const schedule = this.leaderboardSchedules.find(
          schedule =>
            schedule.scheduleName === leaderboardScheduleName
            && schedule.endTime.getTime() >= Date.now()
        );

        if (!schedule) {
          return null;
        }

        return this.$dayjs(schedule.endTime).toDate();
      };
    },
    isRestricted() {
      const config = useRuntimeConfig().public;
      return game => (game.locationRestricted || this.isGamingRestricted) && config.RESTRICT_LOCATIONS;
    },
    tournamentsDisplay() {
      const tournaments = this.tournaments.filter(t => !t?.metadata?.hidden);
      tournaments.forEach((game) => {
        game.assets = {
          imageUrl: game?.metadata?.imageUrl || game.bannerImgUrl,
          animationUrl: game?.metadata?.animationUrl || null,
        };
      });
      return tournaments;
    },
  },
  watch: {
    isUserLogged(newVal) {
      if (newVal) {
        this.init();
      }
    },
  },
  async created() {
    await this.init();
  },
  unmounted() {
    clearInterval(this.poller);
    clearTimeout(this.nextEndTimeTimeout);
    this.poller = null;
    this.nextEndTimeTimeout = null;
  },
  methods: {
    ...mapActions(useMinigamesStore, [
      'loadTournaments',
      'launchGame',
      'loadLeaderboardSchedules',
    ]),
    ...mapActions(useUiStore, [
      'openConnectModal',
    ]),
    async init() {
      if (!this.tournaments.length) {
        await this.loadData();
      }

      if (!this.poller) {
        this.poller = setInterval(() => {
          this.loadData();
        }, 1000 * 60 * 1); // 1 minutes
      }
    },

    async selectGame(game, idx) {
      this.hiddenGameId = null;
      if (this.isDragging) {
        return;
      }

      if (!this.isUserLogged) {
        this.openConnectModal({
          message: 'Experience winning in Web3 by connecting your wallet',
        });
        return;
      }
      if (this.isRestricted(game.game)) {
        return;
      }
      // if (this.$dayjs().isAfter(this.$dayjs(game.endTime))) {
      //   this.hiddenGameId = game.id;
      //   return;
      // }
      this.tournaments[idx].isLoading = true;
      await this.launchGame(game, false);

      this.$gtmCustomEvent({
        event: 'minigame',
        action: 'start',
        minigameName: game?.name,
      });
      this.tournaments.map((ob) => {
        return {
          ...ob,
          isLoading: false,
        };
      });
      game.isLoading = false;
    },
    isLimitedEditionComp(game) {
      return game.game.metadata.isFreeTournament;
    },
    isLimitedEditionCompDateTime(game) {
      return this.isGameWaiting(game.state)
        ? new Date(this.$dayjs(game.startTime).add(1, 'minute'))
        : game.endTime;
    },
    async loadData() {
      await this.loadTournaments();
      await this.loadLeaderboardSchedules();

      if (!Array.isArray(this.leaderboardSchedules)) {
        return;
      }

      this.nextEndTime = _Min(this.leaderboardSchedules.map(s => this.$dayjs(s.endTime).toDate()));

      if (!this.nextEndTime) {
        return;
      }

      clearTimeout(this.nextEndTimeTimeout);

      this.nextEndTimeTimeout = setTimeout(() => {
        this.loadData();
      }, this.nextEndTime.getTime() - Date.now());
    },
    isGameWaiting(state) {
      return state === 1 || state === 2 || state === 3;
    },
    handleToggleDragging(newVal) {
      this.isDragging = newVal;
    },
  },
});
</script>
